/** @format */
import * as actions from '../actions/deviceManager';

const defaultState = {
  list: {
    isLoading: false,
    isError: false,
  },
  listExports: {
    isLoading: false,
    isError: false,
  },
  createExport: {
    isLoading: false,
    isError: false,
  },
  single: {
    isLoading: false,
    isError: false,
  },
  singleExport: {
    isLoading: false,
    isError: false,
  },
  singleExportDetail: {
    isLoading: false,
    isError: false,
  },
};

export const deviceManager = (state = defaultState, action) => {
  switch (action.type) {
    case actions.FETCH_ENTITY_MANAGER_IMPORT_LIST_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: true,
          isError: false,
        },
      };
    case actions.FETCH_ENTITY_MANAGER_IMPORT_LIST_DEVICE_SUCCESS:
      return {
        ...state,
        list: {
          ...action.payload,
          isLoading: false,
          isError: false,
        },
      };
    case actions.FETCH_ENTITY_MANAGER_IMPORT_LIST_DEVICE_FAILURE:
      return {
        ...state,
        list: {
          ...action.payload,
          isLoading: false,
          isError: true,
        },
      };
    case actions.FETCH_ENTITY_MANAGER_IMPORT_DETAIL_REQUEST:
      return {
        ...state,
        single: {
          ...state.single,
          isLoading: true,
          isError: false,
        },
      };
    case actions.FETCH_ENTITY_MANAGER_IMPORT_DETAIL_DEVICE_SUCCESS:
      return {
        ...state,
        single: {
          ...action.payload,
          isLoading: false,
          isError: false,
        },
      };
    case actions.FETCH_ENTITY_MANAGER_IMPORT_DETAIL_DEVICE_FAILURE:
      return {
        ...state,
        single: {
          ...action.payload,
          isLoading: false,
          isError: true,
        },
      };
    case actions.FETCH_ENTITY_MANAGER_EXPORT_LIST_REQUEST:
      return {
        ...state,
        listExports: {
          ...state.listExports,
          isLoading: true,
          isError: false,
        },
      };
    case actions.FETCH_ENTITY_MANAGER_EXPORT_LIST_DEVICE_SUCCESS:
      return {
        ...state,
        listExports: {
          ...action.payload,
          isLoading: false,
          isError: false,
        },
      };
    case actions.FETCH_ENTITY_MANAGER_EXPORT_LIST_DEVICE_FAILURE:
      return {
        ...state,
        listExports: {
          ...action.payload,
          isLoading: false,
          isError: true,
        },
      };
    case actions.CREATE_ENTITY_MANAGER_EXPORT_REQUEST:
      return {
        ...state,
        createExport: {
          ...state.createExport,
          isLoading: true,
          isError: false,
        },
      };
    case actions.CREATE_ENTITY_MANAGER_EXPORT_SUCCESS:
      return {
        ...state,
        createExport: {
          ...action.payload,
          isLoading: false,
          isError: false,
        },
      };
    case actions.CREATE_ENTITY_MANAGER_EXPORT_FAILURE:
      return {
        ...state,
        createExport: {
          ...action.payload,
          isLoading: false,
          isError: true,
        },
      };
    case actions.DESTROY_ENTITY_MANAGER_EXPORT_SUCCESS:
      return {
        ...state,
        createExport: {
          ...defaultState.createExport,
        },
      };
    case actions.FETCH_ENTITY_MANAGER_EXPORT_DETAIL_REQUEST:
      return {
        ...state,
        singleExportDetail: {
          ...state.singleExportDetail,
          isLoading: true,
          isError: false,
        },
      };
    case actions.FETCH_ENTITY_MANAGER_EXPORT_DETAIL_DEVICE_SUCCESS:
      return {
        ...state,
        singleExportDetail: {
          ...action.payload,
          isLoading: false,
          isError: false,
        },
      };
    case actions.FETCH_ENTITY_MANAGER_EXPORT_DETAIL_DEVICE_FAILURE:
      return {
        ...state,
        singleExportDetail: {
          ...action.payload,
          isLoading: false,
          isError: true,
        },
      };
    case actions.DESTROY_ENTITY_MANAGER_EXPORT_DETAIL_SUCCESS:
      return {
        ...state,
        singleExportDetail: {
          ...defaultState.singleExportDetail,
        },
      };
    default:
      return state;
  }
};

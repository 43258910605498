/** @format */

import {
  Button,
  Divider,
  Form,
  Header,
  Input,
  Label,
  Modal,
  Segment,
  Sidebar,
  Message,
  Grid,
} from 'semantic-ui-react';
import React, { Component, Fragment } from 'react';
import Cleave from 'cleave.js/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { connect } from 'react-redux';
import idx from 'idx';
import { withRouter } from 'react-router';
import Axios from 'axios';
import { toast } from 'react-semantic-toasts';
import { deleteGateway, fetchGateway, putGateway } from '../../actions/gateway';
import {
  destroyGatewayProfilesOptions,
  fetchGatewayProfilesOptions,
  fetchGatewayProfileProperties,
} from '../../actions/gatewayProfiles';
import { fetchGatewayProfile } from '../../actions/gatewayProfile';
import { destroyUsersOptions, fetchUsersOptions } from '../../actions/users';
import { fetchBasicstationConfigurations } from '../../actions/basicstationConfigurations';
import { userHasRole, getCurrentUserToken } from '../../lib/auth';

import { GatewayProfileCreate } from '..';
import { ENABLE_RNC, PAGES_PATH } from '../../lib/variables';
import {
  formatBytes,
  formatNormalString,
  isCoordinate,
  coupleOfBytesLengths,
  generatePlaceholder,
  cleaveOptions,
  formatOptions,
  // clean,
  validateEmail,
  validatePassword,
} from '../../lib/utilities';
import { Endpoints as url } from '../../lib/endpoints';
import { generateUrl } from '../../lib/urls';
import history from '../../history';

const mapStateToProps = state => ({
  usersOptions: state.usersOptions,
  gatewayProfilesOptions: state.gatewayProfilesOptions,
  gateway: state.gateway.data || {},
  gatewayProfile: state.gatewayProfile,
  gatewayProfileProperties: state.gatewayProfiles.data,
  basicstationConfigurations: state.basicstationConfigurations,
});

const mapDispatchToProps = dispatch => ({
  fetchGateway: (user, gateway) => {
    dispatch(fetchGateway(user, gateway));
  },
  fetchUsersOptions: (params = {}) => {
    dispatch(fetchUsersOptions(params));
  },
  fetchGatewayProfilesOptions: (user, params = {}) => {
    dispatch(fetchGatewayProfilesOptions(user, params));
  },
  fetchGatewayProfileProperties: () => {
    dispatch(fetchGatewayProfileProperties());
  },
  fetchGatewayProfile: gatewayProfileId => {
    dispatch(fetchGatewayProfile(gatewayProfileId));
  },
  putGateway: (gateway, gatewayProfileId) => {
    dispatch(putGateway(gateway, gatewayProfileId));
  },
  deleteGateway: (gateway, gatewayId) => {
    dispatch(deleteGateway(gateway, gatewayId));
  },
  destroyUsersOptions: () => {
    dispatch(destroyUsersOptions());
  },
  destroyGatewayProfilesOptions: () => {
    dispatch(destroyGatewayProfilesOptions());
  },
  fetchBasicstationConfigurations: () => {
    dispatch(fetchBasicstationConfigurations());
  },
});

class GatewayEditForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      defaultFormWidth: 8,
      gateway: {
        id: '',
        UserId: '',
        band: '',
        maxTxPower: '',
        supportsClassB: false,
        supportsLbt: false,
      },
      gatewayProfileSidebar: false,
      errors: {
        lat: false,
        lng: false,
        alt: false,
        rctrlPassword: false,
      },
    };

    this.handleGatewayProfileSidebar = this.handleGatewayProfileSidebar.bind(
      this,
    );
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeNumber = this.handleChangeNumber.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleChangeUserId = this.handleChangeUserId.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteModal = this.handleDeleteModal.bind(this);
    this.handleSearchProfile = this.handleSearchProfile.bind(this);
    this.handleSearchUserId = this.handleSearchUserId.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateField = this.validateField.bind(this);
    this.onDeviceBlur = this.onDeviceBlur.bind(this);
    this.handleChangeCleave = this.handleChangeCleave.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  componentDidMount() {
    Axios.get(url.Gateway(this.props.match.params.gid), {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
    })
      .then(responseFromGatewayFetch =>
        Axios.get(
          url.GatewayProfile(responseFromGatewayFetch.data.GatewayProfileId),
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: getCurrentUserToken(),
            },
          },
        )
          .then(responseFromGatewayProfileFetch =>
            this.setState({
              ...this.state,
              gateway: {
                ...this.state.gateway,
                ...responseFromGatewayProfileFetch.data,
                ...responseFromGatewayFetch.data,
              },
            }),
          )
          .catch(error => {
            toast({
              title: 'error',
              description: error.response.data.message || 'generic error',
              color: 'red',
              type: 'error',
              time: 4000,
            });
          }),
      )
      .catch(error => {
        toast({
          title: 'error',
          description: error.response.data.message || 'generic error',
          color: 'red',
          type: 'error',
          time: 4000,
        });
      });

    this.props.fetchGatewayProfileProperties();
    this.props.fetchBasicstationConfigurations();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState !== this.state) {
      return true;
    }

    if (nextProps.usersOptions.options !== this.props.usersOptions.options) {
      return true;
    }

    if (
      nextProps.gatewayProfilesOptions.options !==
      this.props.gatewayProfilesOptions.options
    ) {
      return true;
    }

    if (nextProps.gatewayProfile !== this.props.gatewayProfile) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.gateway.UserId !== prevState.gateway.UserId) {
      if (userHasRole('admin')) {
        const params = { filter: { username: this.state.gateway.username } };
        this.props.fetchUsersOptions(params);
      }
    }

    if (
      this.state.gateway.GatewayProfileId !== prevState.gateway.GatewayProfileId
    ) {
      this.props.fetchGatewayProfilesOptions(this.state.gateway.UserId);
    }

    if (prevProps.gatewayProfile.id !== this.props.gatewayProfile.id) {
      const params = { filter: { label: this.props.gatewayProfile.label } };
      this.props.fetchGatewayProfilesOptions(this.state.gateway.UserId, params);
      this.setState({
        gateway: {
          ...this.state.gateway,
          GatewayProfileId: this.props.gatewayProfile.id,
        },
      });
    }
  }

  componentWillUnmount() {
    this.props.destroyUsersOptions();
    this.props.destroyGatewayProfilesOptions();
  }

  // HANDLER SECTION
  handleGatewayProfileSidebar(e) {
    e.preventDefault();
    this.setState({ gatewayProfileSidebar: !this.state.gatewayProfileSidebar });
  }

  handleChange(e, { name, value }) {
    if (name === 'rctrlUsername' || name === 'rctrlPassword') {
      name === 'rctrlUsername'
        ? this.setState({
            gateway: {
              ...this.state.gateway,
              rctrl: { ...this.state.gateway.rctrl, username: value },
            },
          })
        : this.setState({
            gateway: {
              ...this.state.gateway,
              rctrl: { ...this.state.gateway.rctrl, password: value },
            },
          });
    } else if (name === 'configuration') {
      this.setState({
        gateway: {
          ...this.state.gateway,
          configuration: value !== '' ? { id: value, data: {} } : null,
        },
      });
    } else if (name === 'ip') {
      this.setState({
        gateway: {
          ...this.state.gateway,
          status: { ...this.state.gateway.status, ip: value },
        },
      });
    } else {
      this.setState({ gateway: { ...this.state.gateway, [name]: value } });
    }
  }

  handleChangeCleave(e) {
    this.setState({
      gateway: { ...this.state.gateway, [e.target.name]: e.target.value },
    });
  }

  handleChangeNumber(e, { name, value }) {
    this.setState({
      gateway: {
        ...this.state.gateway,
        position: { ...this.state.gateway.position, [name]: value },
      },
    });
  }

  handleCheck(e, { name, checked }) {
    this.setState({ gateway: { ...this.state.gateway, [name]: checked } });
  }

  handleChangeUserId(e, { name, value }) {
    e.persist();

    this.setState({
      gateway: {
        ...this.state.gateway,
        UserId: value,
        // username: e.target.textContent,
        GatewayProfileId: null,
      },
    });
  }

  handleClose() {
    this.setState({ modalOpen: false });
  }

  handleDeleteModal() {
    this.setState({ modalOpen: true });
  }

  handleDelete() {
    const { gateway } = this.props;

    this.props.deleteGateway(gateway, this.props.match.params.gid);
    this.setState({ modalOpen: false, check: null });
  }

  handleSearchUserId(e, { searchQuery }) {
    const params = { filter: { username: searchQuery } };
    this.props.fetchUsersOptions(params);
  }

  handleSearchProfile(e, { searchQuery }) {
    const params = { filter: { label: searchQuery } };
    this.props.fetchGatewayProfilesOptions(this.state.gateway.UserId, params);
  }

  handleSubmit(e) {
    const { gateway } = this.state;

    e.preventDefault();

    Axios.get(
      generateUrl(url.GatewayProfilesOptions(), {
        supportsClassB: gateway.supportsClassB,
        supportsLbt: gateway.supportsLbt,
        band: gateway.band,
        maxTxPower: gateway.maxTxPower,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: getCurrentUserToken(),
        },
      },
    )
      .then(
        response =>
          response.status === 200 &&
          this.setState(
            {
              ...this.state,
              gateway: {
                ...this.state.gateway,
                GatewayProfileId: response.data.rows[0].id,
              },
            },
            () => {
              let formattedGateway = {
                ...gateway,
                GatewayProfileId: response.data.rows[0].id,
              };

              if (formattedGateway.supportsRemoteControl) {
                formattedGateway.rctrlUsername =
                  formattedGateway.rctrl.username;
                formattedGateway.rctrlPassword =
                  formattedGateway.rctrl.password;
              }

              delete formattedGateway.maxTxPower;
              delete formattedGateway.band;
              delete formattedGateway.supportsClassB;
              delete formattedGateway.supportsLbt;
              delete formattedGateway.createdAt;
              delete formattedGateway.updatedAt;
              delete formattedGateway.shares;
              delete formattedGateway.status;
              delete formattedGateway.rctrl;
              delete formattedGateway.id;
              delete formattedGateway.status;
              !userHasRole('admin') && delete formattedGateway.UserId;

              formattedGateway.ip = gateway.status.ip || null;

              if (!formattedGateway.model) delete formattedGateway.model;
              if (!formattedGateway.serial) delete formattedGateway.serial;
              if (!formattedGateway.firmware) delete formattedGateway.firmware;

              // set configurationId
              if (formattedGateway.configuration)
                formattedGateway.configurationId =
                  formattedGateway.configuration.id;
              else formattedGateway.configurationId = null;

              delete formattedGateway.configuration;

              formattedGateway = {
                ...formattedGateway,
                lat: Number(formattedGateway.position.lat),
                lng: Number(formattedGateway.position.lng),
                alt: Number(formattedGateway.position.alt),
              };

              delete formattedGateway.position;

              Axios.put(
                url.GatewayUpdate(gateway.UserId, gateway.id),
                JSON.stringify(formattedGateway),
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: getCurrentUserToken(),
                  },
                },
              )
                .then(() => {
                  history.push({
                    pathname: `${PAGES_PATH}/gateway/${gateway.id}`,
                  });
                })
                .catch(error => {
                  toast({
                    title: 'error',
                    description: error.response.data.message || 'generic error',
                    color: 'red',
                    type: 'error',
                    time: 4000,
                  });
                });
            },
          ),
      )
      .catch(error => {
        toast({
          title: 'error',
          description: error.response.data.message || 'generic error',
          color: 'red',
          type: 'error',
          time: 4000,
        });
      });
  }

  handleBlur(e) {
    const fieldToValidate = e.target.name;

    switch (fieldToValidate) {
      case 'email':
        if (validateEmail(e.target.value)) {
          this.setState({
            errors: { ...this.state.errors, [fieldToValidate]: false },
          });
        } else {
          this.setState({
            errors: { ...this.state.errors, [fieldToValidate]: true },
          });
        }
        break;
      case 'rctrlPassword':
        if (validatePassword(e.target.value)) {
          this.setState({
            errors: { ...this.state.errors, [fieldToValidate]: false },
          });
        } else {
          this.setState({
            errors: { ...this.state.errors, [fieldToValidate]: true },
          });
        }
        break;
      default:
        throw new Error('Cannot validate this field.');
    }
  }

  validateField(ev) {
    if (ev.target.value === '') {
      return this.setState({
        errors: { ...this.state.errors, [ev.target.name]: false },
      });
    }
    return isCoordinate(ev.target.value, ev.target.name)
      ? this.setState({
          errors: { ...this.state.errors, [ev.target.name]: false },
        })
      : this.setState({
          errors: { ...this.state.errors, [ev.target.name]: true },
        });
  }

  onDeviceBlur(event, bytesEnum) {
    const reducer = formatNormalString(
      event.target.value.replace(/^0+/g, ''),
      ':',
    );

    this.setState({
      ...this.state,
      gateway: {
        ...this.state.gateway,
        [event.target.name]: reducer.padStart(bytesEnum * 2, '0'),
      },
    });
  }

  render() {
    const {
      gatewayProfileSidebar,
      errors,
      gateway,
      defaultFormWidth,
    } = this.state;
    const {
      usersOptions,
      gatewayProfileProperties,
      basicstationConfigurations,
    } = this.props;

    return (
      <Fragment>
        <Header as="h1" style={{ arginTop: '1em' }} floated="left">
          Edit Gateway
        </Header>
        <Button
          key="delete"
          compact
          icon
          color="red"
          labelPosition="left"
          floated="right"
          style={{ marginTop: '2.4em' }}
          onClick={() => this.handleDeleteModal()}
        >
          <FontAwesomeIcon
            icon="trash-alt"
            className="icon"
            style={{ padding: '0.4em' }}
          />
          Delete
        </Button>
        <Button
          as={Link}
          key="list"
          compact
          icon
          color="red"
          labelPosition="left"
          floated="right"
          style={{ marginTop: '2.4em' }}
          to={`${PAGES_PATH}/gateways`}
        >
          <FontAwesomeIcon
            icon="list"
            className="icon"
            style={{ padding: '0.4em' }}
          />
          List
        </Button>
        {ENABLE_RNC ? (
          <Button
            as={Link}
            key="rnc"
            compact
            icon
            color="red"
            labelPosition="left"
            floated="right"
            style={{ marginTop: '2.4em' }}
            to={`${PAGES_PATH}/gateway/${gateway.id}/rnc`}
          >
            <FontAwesomeIcon
              icon={faCogs}
              className="icon"
              style={{ padding: '0.4em' }}
            />
            RNC
          </Button>
        ) : (
          <span />
        )}

        <Divider clearing />

        <Sidebar.Pushable>
          <Sidebar
            as={Segment}
            inverted
            color="red"
            direction="right"
            animation="overlay"
            visible={gatewayProfileSidebar}
            width="very wide"
          >
            <Button
              floated="right"
              onClick={this.handleGatewayProfileSidebar}
              size="mini"
            >
              close
            </Button>
            <Header floated="left" as="h2">
              New Application
            </Header>
            <Divider clearing inverted />
            {idx(gateway, _ => _.UserId) && (
              <GatewayProfileCreate
                inverted
                UserId={idx(gateway, _ => _.UserId)}
                handleGatewayProfileSidebar={this.handleGatewayProfileSidebar}
              />
            )}
          </Sidebar>

          <Sidebar.Pusher>
            <Form onSubmit={this.handleSubmit}>
              {userHasRole('admin') && (
                <Form.Dropdown
                  name="UserId"
                  label="Owner"
                  placeholder="choose one..."
                  width={defaultFormWidth}
                  search
                  selection
                  required
                  options={
                    idx(usersOptions, _ => formatOptions(_.options, 'Owner')) ||
                    []
                  }
                  value={idx(gateway, _ => _.UserId)}
                  onChange={this.handleChangeUserId}
                  onSearchChange={this.handleSearchUserId}
                  disabled={idx(usersOptions, _ => _.isFetching)}
                  loading={idx(usersOptions, _ => _.isFetching)}
                />
              )}

              <Form.Dropdown
                name="band"
                label="Band"
                placeholder="choose one..."
                width={defaultFormWidth}
                search
                selection
                required
                options={
                  idx(gatewayProfileProperties, _ =>
                    formatOptions(_.band, 'Band'),
                  ) || []
                }
                value={idx(gateway, _ => _.band)}
                onChange={this.handleChange}
                disabled={!idx(gateway, _ => _.UserId)}
              />

              <Form.Dropdown
                name="maxTxPower"
                label="Max Tx Power"
                placeholder="choose one..."
                width={defaultFormWidth}
                search
                selection
                required
                options={
                  idx(gatewayProfileProperties, _ =>
                    formatOptions(_.maxTxPower, 'Max Tx Power'),
                  ) || []
                }
                value={idx(gateway, _ => _.maxTxPower)}
                onChange={this.handleChange}
                disabled={!idx(gateway, _ => _.UserId)}
              />

              <Form.Checkbox
                toggle
                label="Supports Class B"
                name="supportsClassB"
                checked={gateway.supportsClassB}
                onChange={this.handleCheck}
                disabled={!idx(gateway, _ => _.UserId)}
              />

              <Form.Checkbox
                toggle
                label="Supports Lbt"
                name="supportsLbt"
                checked={gateway.supportsLbt}
                onChange={this.handleCheck}
                disabled={!idx(gateway, _ => _.UserId)}
              />

              <Form.Checkbox
                toggle
                label="Make this Gateway public"
                name="public"
                checked={idx(gateway, _ => _.public)}
                onChange={this.handleCheck}
                disabled={!(gateway.UserId && gateway.GatewayProfileId)}
              />

              <Form.Dropdown
                name="configuration"
                label="Basic Station Configuration"
                placeholder="choose one..."
                width={defaultFormWidth}
                search
                selection
                clearable
                options={
                  idx(basicstationConfigurations.rows, _ =>
                    formatOptions(_, 'BasicstationConfigurations'),
                  ) || []
                }
                value={idx(gateway.configuration, _ => _.id)}
                onChange={this.handleChange}
                disabled={!idx(gateway, _ => _.UserId)}
              />

              <Form.Input
                width={defaultFormWidth}
                label="Label"
                name="label"
                required
                value={idx(gateway, _ => _.label) || 'No name'}
                onChange={this.handleChange}
                disabled={!idx(gateway, _ => _.UserId)}
              />

              <Form.Input width={defaultFormWidth} label="Gateway ID" disabled>
                <Cleave
                  placeholder={generatePlaceholder(
                    coupleOfBytesLengths.GATEWAYID,
                  )}
                  name="id"
                  value={formatBytes(gateway.id, null)}
                  options={{
                    ...cleaveOptions,
                    blocks: new Array(coupleOfBytesLengths.GATEWAYID).fill(2),
                  }}
                  onBlur={ev =>
                    this.onDeviceBlur(ev, coupleOfBytesLengths.GATEWAYID)
                  }
                  onChange={this.handleChangeCleave}
                />
              </Form.Input>

              <Header as="h4">Remote Control Support</Header>
              <Divider clearing />

              <Form.Checkbox
                toggle
                label="This gateway supports Remote Control"
                name="supportsRemoteControl"
                checked={idx(gateway, _ => _.supportsRemoteControl)}
                onChange={this.handleCheck}
                disabled={!idx(gateway, _ => _.UserId)}
              />

              <Grid>
                {gateway && gateway.supportsRemoteControl === true && (
                  <Grid.Column mobile={16} tablet={7} computer={7}>
                    <Form.Input
                      width={defaultFormWidth}
                      label="Username"
                      name="rctrlUsername"
                      required
                      value={idx(gateway, _ => _.rctrl.username) || ''}
                      onChange={this.handleChange}
                      disabled={!idx(gateway, _ => _.UserId)}
                    />
                    <Form.Input
                      width={defaultFormWidth}
                      label="Password"
                      name="rctrlPassword"
                      required
                      value={idx(gateway, _ => _.rctrl.password) || ''}
                      onBlur={this.handleBlur}
                      onChange={this.handleChange}
                      disabled={!idx(gateway, _ => _.UserId)}
                    />
                  </Grid.Column>
                )}
                <Grid.Column
                  floated="right"
                  mobile={16}
                  tablet={7}
                  computer={7}
                >
                  {errors.rctrlPassword && (
                    <Message negative>
                      <Message.Header>Password not valid.</Message.Header>
                      <p>
                        Your password must be at least defaultFormWidth
                        characters long.
                      </p>
                    </Message>
                  )}
                </Grid.Column>
              </Grid>

              <Header as="h4">Network</Header>
              <Divider clearing />

              <Grid padded columns={2}>
                <Grid.Column mobile={16} tablet={7} computer={7}>
                  <Form.Input
                    // width={ defaultFormWidth }
                    label="IP Address"
                    name="ip"
                    value={idx(gateway, _ => _.status.ip) || ''}
                    onChange={this.handleChange}
                    disabled={!idx(gateway, _ => _.UserId)}
                  />
                </Grid.Column>
              </Grid>

              <Header as="h4">Position</Header>
              <Divider clearing />

              <Grid padded columns={2}>
                <Grid.Column mobile={16} tablet={7} computer={7}>
                  <Form.Input
                    // width={ defaultFormWidth }
                    label="Latitude"
                    name="lat"
                    value={idx(gateway, _ => _.position.lat) || ''}
                    onChange={this.handleChangeNumber}
                    disabled={!idx(gateway, _ => _.UserId)}
                  />

                  <Form.Input
                    // width={ defaultFormWidth }
                    label="Longitude"
                    name="lng"
                    value={idx(gateway, _ => _.position.lng) || ''}
                    onChange={this.handleChangeNumber}
                    disabled={!idx(gateway, _ => _.UserId)}
                  />

                  <Form.Input
                    // width={ defaultFormWidth }
                    label="Altitude"
                    name="alt"
                    value={idx(gateway, _ => _.position.alt) || ''}
                    onChange={this.handleChangeNumber}
                    disabled={!idx(gateway, _ => _.UserId)}
                  />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={7} computer={7}>
                  {(errors.lat || errors.lng || errors.alt) && (
                    <Message negative>
                      <Message.Header>Set all coordinates</Message.Header>
                      <p>Please check your coordinates</p>
                    </Message>
                  )}
                </Grid.Column>
              </Grid>

              <Header as="h4">Info</Header>
              <Divider clearing />

              <Form.Input
                width={defaultFormWidth}
                label="Model"
                name="model"
                value={idx(gateway, _ => _.model) || ''}
                onChange={this.handleChange}
                disabled={!idx(gateway, _ => _.UserId)}
              />

              <Form.Input
                width={defaultFormWidth}
                label="Serial"
                name="serial"
                value={idx(gateway, _ => _.serial) || ''}
                onChange={this.handleChange}
                disabled={!idx(gateway, _ => _.UserId)}
              />

              <Form.Input
                width={defaultFormWidth}
                label="Firmware"
                name="firmware"
                value={idx(gateway, _ => _.firmware) || ''}
                onChange={this.handleChange}
                disabled={!idx(gateway, _ => _.UserId)}
              />

              <Form.Button
                content="Save"
                type="submit"
                disabled={!idx(gateway, _ => _.UserId)}
              />
            </Form>
          </Sidebar.Pusher>
        </Sidebar.Pushable>

        {idx(gateway, _ => _.id) && (
          <Modal
            open={this.state.modalOpen}
            onClose={this.handleClose}
            basic
            size="small"
          >
            <div style={{ width: '40%' }}>
              <SVG src="/assets/images/robotRYS.svg" />
            </div>
            <Header as="h1" content="Are you sure??" />
            <Modal.Content>
              <h3>
                This action will delete this gateway and all related shares.
              </h3>
              <h4>WARNING, THIS ACTION IS NOT REVERSIBLE!</h4>
              <p>To proceed, please fill the field with the Gateway ID</p>
              <Label size="big" color="orange" style={{ marginBottom: '2em' }}>
                {gateway.id}
              </Label>
              <div>
                <Input
                  name="check"
                  placeholder="..."
                  width={defaultFormWidth}
                  onChange={this.handleChange}
                />
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button
                onClick={this.handleDelete}
                color="red"
                disabled={gateway.id !== gateway.check}
              >
                Proceed
              </Button>
              <Button onClick={this.handleClose}>Cancel</Button>
            </Modal.Actions>
          </Modal>
        )}
      </Fragment>
    );
  }
}

GatewayEditForm.propTypes = {
  match: PropTypes.object,
  gateway: PropTypes.object,
  usersOptions: PropTypes.object,
  gatewayProfilesOptions: PropTypes.object,
  gatewayProfile: PropTypes.object,
  fetchUsersOptions: PropTypes.func,
  fetchGateway: PropTypes.func,
  fetchGatewayProfilesOptions: PropTypes.func,
  destroyUsersOptions: PropTypes.func,
  destroyGatewayProfilesOptions: PropTypes.func,
  destroyGateway: PropTypes.func,
  deleteGateway: PropTypes.func,
  putGateway: PropTypes.func,
  fetchGatewayProfileProperties: PropTypes.func,
  gatewayProfileProperties: PropTypes.object,
  basicstationConfigurations: PropTypes.object,
  fetchBasicstationConfigurations: PropTypes.func,
};

const GatewayUpdate = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GatewayEditForm),
);

export { GatewayUpdate };

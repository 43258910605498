/** @format */

import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import DefaultButton from '../../../components/Utils/DefaultButton';
import {
  createExportEntities,
  destroyExportEntityDetail,
} from '../../../actions/deviceManager';
import { toastSuccess } from '../../../lib/utilities';

const ButtonExport = ({ label, entity, filters }) => {
  const { createExport: create } = useSelector(state => state.deviceManager);

  const createIdRef = useRef(create.id || null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (create) {
      const { id: createId = null } = create || {};

      if (createId && createIdRef.current !== createId) {
        createIdRef.current = createId;
        toastSuccess(createId, 'Exported successfully');
      }
    }
  }, [create]);

  const onExport = () => {
    dispatch(destroyExportEntityDetail());
    dispatch(createExportEntities(entity, filters));
  };

  return (
    <>
      <DefaultButton icon={'file-csv'} desc={label} onClick={onExport} />
    </>
  );
};

ButtonExport.propTypes = {
  label: PropTypes.string,
  entity: PropTypes.string,
  filters: PropTypes.object,
};

export default ButtonExport;

/** @format */

import React, { Component, Fragment, createRef } from 'react';
import { PropTypes } from 'prop-types';
import { Divider, Grid, Label, Icon, Ref, Sticky } from 'semantic-ui-react';
import { Link, Route, Router, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { clearConsole, closeSocketConnection } from '../../actions/uplinks';
import {
  deleteCurrentUserToken,
  getCurrentUser,
  isAccessToken,
} from '../../lib/auth';
import {
  Applications,
  DeviceImport,
  Devices,
  Footer,
  Gateways,
  MainMenu,
  Statistics,
  Users,
} from '../../components';
import {
  AccessTokensRead,
  ApplicationAddTo,
  ApplicationRead,
  ApplicationShareCreate,
  ApplicationUpdate,
  ChangePassword,
  ViewConsole as Console,
  DeviceCreate,
  DeviceDownlink,
  DeviceRead,
  DeviceShareCreate,
  DeviceUpdate,
  GatewayCreate,
  GatewayProfileRead,
  GatewayProfileUpdate,
  GatewayRead,
  GatewayShareCreate,
  GatewayUpdate,
  MacCommand,
  Me,
  MqttClient,
  MulticastGroupAddTo,
  MulticastGroupRead,
  MulticastGroupUpdate,
  UserCreate,
  UserProfileCreatePage,
  UserProfileRead,
  UserProfileUpdate,
  UserRead,
  UserUpdate,
} from '../../containers';
import history from '../../history';
import { Docs } from '../Docs';
import { NotFound } from '..';
import { PAGES_PATH } from '../../lib/variables';

import './Dashboard.css';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import SpectralScan from '../../containers/Rnc/SpectralScan';
import PoweroffHistory from '../../containers/Rnc/PoweroffHistory';
import { RncConfiguration } from '../../containers/Rnc/RncConfiguration';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  clearConsole: () => {
    dispatch(clearConsole());
  },
  closeSocketConnection: () => {
    dispatch(closeSocketConnection());
  },
});

class Dashboard extends Component {
  contextRef = createRef();

  logOut = () => {
    this.props.clearConsole();
    this.props.closeSocketConnection();
    deleteCurrentUserToken();
    history.push('/');
  };

  render() {
    const user = getCurrentUser();
    return (
      <Fragment>
        <Me>
          <MqttClient>
            <Grid style={{ fontSize: '.7em' }}>
              <Grid.Row only={'tablet mobile'}>
                <Grid.Column style={{ paddingRight: 0 }}>
                  <Router history={history}>
                    <MainMenu
                      hasIcons={false}
                      mobile={true}
                      logOut={this.logOut}
                    />
                  </Router>
                </Grid.Column>
              </Grid.Row>
              <Grid.Column
                computer={1}
                only={'computer'}
                className="dadhboard-grid-left"
                textAlign={'center'}
              >
                <Sticky context={this.contextRef}>
                  <Router history={history}>
                    <MainMenu logOut={this.logOut} />
                  </Router>
                </Sticky>
              </Grid.Column>
              <Grid.Column computer={15} mobile={16} tablet={16}>
                <Ref innerRef={this.contextRef}>
                  <Grid>
                    <Grid.Row style={{ paddingBottom: '.1em' }}>
                      <Grid.Column>
                        <Link to={`${PAGES_PATH}/profile`}>
                          <Label
                            color="teal"
                            style={{
                              margin: '.2em',
                              float: 'right',
                              minWidth: '10em',
                            }}
                          >
                            <Icon
                              name={
                                isAccessToken() ? 'user secret' : 'user circle'
                              }
                              size="big"
                              style={{ verticalAlign: 'top' }}
                            />
                            <div style={{ display: 'inline-block' }}>
                              <div
                                style={{ display: 'block', textAlign: 'end' }}
                              >
                                {user.username}
                              </div>
                              <Label.Detail
                                style={{ display: 'block', textAlign: 'end' }}
                              >
                                <small>{user.role}</small>
                              </Label.Detail>
                            </div>
                          </Label>
                        </Link>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ paddingTop: '.1em' }}>
                      <Grid.Column>
                        <div style={{ minHeight: '70vh' }}>
                          <Switch>
                            <Route
                              exact
                              path={`${PAGES_PATH}`}
                              component={Devices}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/applications`}
                              component={Applications}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/application/add-to`}
                              component={ApplicationAddTo}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/application/share`}
                              component={ApplicationShareCreate}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/application/:aid`}
                              component={ApplicationRead}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/application/:aid/edit`}
                              component={ApplicationUpdate}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/change-password`}
                              component={ChangePassword}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/devices`}
                              component={Devices}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/device/share`}
                              component={DeviceShareCreate}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/device/add`}
                              component={DeviceCreate}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/device/:did`}
                              component={DeviceRead}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/device/:did/edit`}
                              component={DeviceUpdate}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/device/:did/maccommand`}
                              component={MacCommand}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/device/:did/downlink`}
                              component={DeviceDownlink}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/gateways`}
                              component={Gateways}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/gateway/share`}
                              component={GatewayShareCreate}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/gateway/add`}
                              component={GatewayCreate}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/gateway/:gid`}
                              component={GatewayRead}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/gateway/:gid/edit`}
                              component={GatewayUpdate}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/gateway/:gid/poweroff-history`}
                              component={PoweroffHistory}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/gateway/:gid/spectral-scann`}
                              component={SpectralScan}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/gateway/:gid/rnc`}
                              component={RncConfiguration}
                            />

                            <Route
                              exact
                              path={`${PAGES_PATH}/gateway-profile/:gpid`}
                              component={GatewayProfileRead}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/gateway-profile/:gpid/edit`}
                              component={GatewayProfileUpdate}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/multicast/add`}
                              component={MulticastGroupAddTo}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/multicast/:mgid`}
                              component={MulticastGroupRead}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/multicast/:mgid/edit`}
                              component={MulticastGroupUpdate}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/users`}
                              component={Users}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/user/add`}
                              component={UserCreate}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/user/access-tokens`}
                              component={AccessTokensRead}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/user/:uid/edit`}
                              component={UserUpdate}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/user/:uid`}
                              component={UserRead}
                              key="users-profiles"
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/user-profile/add`}
                              component={UserProfileCreatePage}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/user-profile/:uid/edit`}
                              component={UserProfileUpdate}
                              key="user-profile-update"
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/user-profile/:uid`}
                              component={UserProfileRead}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/profile`}
                              component={UserRead}
                              key="my-profile"
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/console`}
                              component={Console}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/statistics`}
                              component={Statistics}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/import/:entity/:id`}
                              component={DeviceImport}
                            />
                            <Route
                              exact
                              path={`${PAGES_PATH}/docs`}
                              component={Docs}
                            />
                            <Route component={NotFound} />
                          </Switch>
                          <Divider hidden />
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Footer />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Ref>
              </Grid.Column>
            </Grid>
          </MqttClient>
        </Me>
      </Fragment>
    );
  }
}

Dashboard.propTypes = {
  clearConsole: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  closeSocketConnection: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
};

const DashboardRedux = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard),
);

export { DashboardRedux as Dashboard };

/** @format */

import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { useTimeout, useInterval } from '../Hooks';
import {
  destroyExportEntities,
  fetchEntityManagerExportDetail,
  destroyExportEntityDetail,
} from '../../../actions/deviceManager';
import { downloadCsv } from '../Utils/Download';
import { toastWarning } from '../../../lib/utilities';

const ExportEntityDownloadLink = ({ entity }) => {
  const [dataInCSV, setDataInCSV] = useState(null);
  const [abortTimeout, setAbortTimeout] = useState(true);
  const [abortInterval, setAbortInterval] = useState(true);

  const {
    createExport: { id: createId = null },
    singleExportDetail: { status: detailStatus = null },
  } = useSelector(state => state.deviceManager);

  const createIdRef = useRef(createId || null);

  const dispatch = useDispatch();

  // close the message
  const handleDismiss = () => {
    setAbortTimeout(true);
    setAbortInterval(true);
    dispatch(destroyExportEntities());
    dispatch(destroyExportEntityDetail());
  };

  // download the export file
  const handleDownloadExport = () => {
    handleDownload(createId);
    // dispatch(destroyExportEntities());
  };

  // download the export file
  const handleDownload = id => {
    downloadCsv(id)
      .then(res => setDataInCSV(res))
      .catch(err => toastWarning('Try again in a few seconds', 'Warning'));
  };

  useEffect(() => {
    if (createId) {
      if (createIdRef.current !== createId) {
        // reset the abort flags, the interval starts
        setAbortInterval(false);
        createIdRef.current = createId;
      }
    } else {
      // reset the abort flags, the interval starts
      setAbortInterval(true);
      createIdRef.current = null;
    }
  }, [createId]);

  useEffect(() => {
    if (dataInCSV) {
      // create a download link
      const url = window.URL.createObjectURL(
        new Blob([dataInCSV], { type: 'text/csv' }),
      );

      // create a link and click it
      const link = document.createElement('a');

      // set the link properties
      link.href = url;

      // set the link properties
      link.setAttribute('download', `${createId}.csv`);

      // append the link to the body
      document.body.appendChild(link);

      // click the link
      link.click();

      // remove the link
      link.remove();

      // set the data to null
      setDataInCSV(null);
    }
  }, [dataInCSV]);

  useEffect(() => {
    if (detailStatus && detailStatus === 'success') {
      setAbortTimeout(true);
      setAbortInterval(true);
      setAbortTimeout(false);
    }
  }, [detailStatus]);

  useTimeout(
    () => {
      setAbortTimeout(true);
      setAbortInterval(true);
      dispatch(destroyExportEntities());
      dispatch(destroyExportEntityDetail(createId));
    },
    abortTimeout ? null : 25000,
  );

  useInterval(() => {
    if (createId && !abortInterval) {
      dispatch(fetchEntityManagerExportDetail(entity, createId));
    }
  }, [abortInterval ? null : 5000]);

  return (
    <>
      {createId && (
        <Message
          color="blue"
          icon="info circle"
          content={
            <>
              {detailStatus === 'success' && (
                <>
                  <Button
                    color="blue"
                    size="mini"
                    onClick={handleDownloadExport}
                  >
                    Click Here
                  </Button>{' '}
                  to download the export <b>{createId}</b>
                </>
              )}
              {!detailStatus && (
                <>
                  We are processing your request. This may take a few seconds.
                </>
              )}
            </>
          }
          onDismiss={handleDismiss}
        />
      )}
    </>
  );
};

ExportEntityDownloadLink.propTypes = {
  entity: PropTypes.string.isRequired,
};

export default ExportEntityDownloadLink;

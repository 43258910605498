/** @format */

/* eslint-disable react/display-name */

import React from 'react';
import { TabPane, Tab } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { EntitiesImport, EntitiesExport } from '../../containers';

const DevicesMassiveOperations = ({ entity }) => {
  const panes = [
    {
      menuItem: 'Import',
      render: () => (
        <TabPane>
          <EntitiesImport entity={entity} />
        </TabPane>
      ),
    },
    {
      menuItem: 'Export',
      render: () => (
        <TabPane>
          <EntitiesExport entity={entity} />
        </TabPane>
      ),
    },
  ];

  return (
    <>
      <Tab
        menu={{ secondary: true, pointing: true }}
        panes={panes}
        defaultActiveIndex={0}
      />
    </>
  );
};

DevicesMassiveOperations.propTypes = {
  entity: PropTypes.string,
};

export { DevicesMassiveOperations };

/** @format */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { useInterval } from '../Hooks';
import { EntityExportListTable } from './EntityExportListTable';

import { fetchEntityManagerExportList } from '../../../actions/deviceManager';

const EntitiesExport = ({ entity }) => {
  const [items, setItems] = useState([]);
  const [delay, setDelay] = useState(5000);

  const {
    listExports: list = { rows: [] },
    listExports: { offset = 0, limit = 10, size = null },
  } = useSelector(state => state.deviceManager);

  const dispatch = useDispatch();

  useInterval(() => {
    dispatch(
      fetchEntityManagerExportList(entity, {
        offset,
        limit,
      }),
    );
  }, delay);

  useEffect(() => {
    dispatch(
      fetchEntityManagerExportList(entity, {
        offset: list.offset,
        limit: list.limit,
      }),
    );

    return () => {
      return () => setDelay(null);
    };
  }, []);

  useEffect(() => {
    if (list && 'rows' in list) setItems(list.rows);
  }, [list]);

  const onPageChange = (e, { activePage }) => {
    dispatch(
      fetchEntityManagerExportList(entity, {
        offset: (activePage - 1) * limit,
        limit,
      }),
    );
  };

  return (
    <>
      {/* <pre>{JSON.stringify(items, 0, 2)}</pre> */}
      <EntityExportListTable
        items={items}
        pagination={{
          offset,
          limit,
          size,
        }}
        onPageChange={onPageChange}
      />
    </>
  );
};

EntitiesExport.propTypes = {
  entity: PropTypes.string,
};

export { EntitiesExport };

/** @format */

import React, { useState } from 'react';
import {
  Button,
  ButtonGroup,
  ButtonOr,
  Checkbox,
  Divider,
  Header,
  Icon,
  Select,
  Segment,
  Container,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import Axios from 'axios';

import { getCurrentUserToken } from '../../../lib/auth';
import { Endpoints as url } from '../../../lib/endpoints';
import { UploadModal } from './UploadModal';

import './Upload.css';

function humanFileSize(size) {
  var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return (
    +(size / Math.pow(1024, i)).toFixed(2) * 1 +
    ' ' +
    ['B', 'kB', 'MB', 'GB', 'TB'][i]
  );
}

const Upload = ({ entity, onUploadFinished }) => {
  const [file, setFile] = useState(null);
  const [kind, setKind] = useState('create');
  const [notAllowMove, setNotAllowMove] = useState(false);
  const [open, setOpen] = useState(false);

  const onKindChange = (e, { value }) => {
    setKind(value);
  };

  const onDrop = acceptedFiles => {
    setFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: 'text/csv',
    disabled: file,
  });

  const uploadFile = () => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('kind', kind);

    Axios.post(url.EntityManagerUpload(entity), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: getCurrentUserToken(),
        ...(notAllowMove ? { 'x-not-allow-move': notAllowMove } : {}),
      },
    })
      .then(res => onUploadFinished(res))
      .catch(error => onUploadFinished(error));
  };

  const clearFile = () => {
    setFile(null);
  };

  const onModalUpload = (e, { children }) => {
    if (children === 'Proceed') {
      setOpen(false);
      uploadFile();
    } else if (children === 'Cancel') {
      setOpen(false);
    }
  };

  return (
    <>
      <Divider hidden />
      <Select
        placeholder="Select your country"
        defaultValue={kind}
        options={[
          { key: 'create', value: 'create', text: 'Create' },
          { key: 'delete', value: 'delete', text: 'Delete' },
        ]}
        onChange={onKindChange}
      />
      <Divider hidden />
      <>
        {!file && (
          <div {...getRootProps()}>
            <Segment placeholder>
              <Header icon>
                <input {...getInputProps()} />
                <Icon name="file text outline" />
                {isDragActive ? (
                  <>Drop the file here ...</>
                ) : (
                  <>Drag 'n' drop some file here, or click to select file</>
                )}
              </Header>
            </Segment>
          </div>
        )}
        {file && (
          <Segment placeholder>
            <Header icon>
              <Icon name="file text outline" />
              {file.name} [{humanFileSize(file.size)}]
            </Header>
            <Container textAlign="center">
              <ButtonGroup compact>
                <Button basic color="red" onClick={clearFile} toggle>
                  clear
                </Button>
                <ButtonOr />
                <Button
                  onClick={() => setOpen(true)}
                  disabled={!file}
                  positive={!!file}
                  toggle
                >
                  UPLOAD
                </Button>
              </ButtonGroup>
            </Container>
          </Segment>
        )}
      </>
      <Divider hidden />
      <Checkbox
        label={`Prevent ${entity}s movement`}
        checked={notAllowMove}
        onChange={() => {
          setNotAllowMove(!notAllowMove);
        }}
        toggle
      />

      <UploadModal
        entity={entity}
        label={kind}
        open={open}
        onChange={onModalUpload}
      />
    </>
  );
};

Upload.propTypes = {
  entity: PropTypes.string,
  onUploadFinished: PropTypes.func,
};

export { Upload };

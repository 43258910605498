/** @format */

import React, { useState, useEffect } from 'react';
import { Dropdown, Form } from 'semantic-ui-react';
import axios from 'axios';
import PropTypes from 'prop-types';

import { Endpoints as url } from '../../lib/endpoints';
import { getCurrentUserToken } from '../../lib/auth';

const ApplicationDropdown = ({
  uid,
  onChange = (e, { name, value }) => {},
  split = false,
  ...props
}) => {
  const [value, setValue] = useState(null);
  const [applications, setApplications] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    fetchApplications();
  }, [searchQuery, page, uid]);

  useEffect(() => {
    const { event = null, appid: _appid = null } = value || {};

    if (split && _appid) {
      const [appid, UserId] = _appid.split('-');
      onChange(event, { name: 'appid', value: appid, UserId });
    } else onChange(event, { name: 'appid', value: _appid });
  }, [value]);

  const fetchApplications = async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const response = await axios.get(url.Applications(), {
        params: {
          ...(searchQuery ? { label: searchQuery } : {}),
          ...(uid ? { uid } : {}),
          offset: page,
          limit: 20,
        },
        headers: {
          Authorization: `${getCurrentUserToken()}`,
        },
      });

      setApplications([...response.data.rows]);
      setHasMore(response.data.rows.length > 0);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching applications:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (e, { searchQuery: _searchQuery }) => {
    setSearchQuery(_searchQuery);
    setApplications([]);
    setPage(0);
    setHasMore(true);
  };

  const handleOnChange = (event, data) => {
    const { value: appid } = data;

    setValue({ event, data, appid });

    if (appid === '') {
      setSearchQuery();
    }
  };

  const handleScroll = e => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom && hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  };

  return (
    <>
      <Form.Field
        search
        selection
        options={applications.map(app => ({
          key: `${app.appid}_${app.UserId}`,
          text: `${app.label}`,
          value: `${app.appid}-${app.UserId}`,
        }))}
        onSearchChange={handleSearchChange}
        onScroll={handleScroll}
        loading={loading}
        clearable
        onChange={handleOnChange}
        control={Dropdown}
        {...props}
      />
    </>
  );
};

ApplicationDropdown.propTypes = {
  uid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  split: PropTypes.bool,
};

export { ApplicationDropdown };

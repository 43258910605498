/** @format */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios/index';
import idx from 'idx/lib/idx';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Message,
  Segment,
  Sidebar,
  Table,
} from 'semantic-ui-react';
import { UserProfileCreate } from '..';
import { destroyUser, postUser } from '../../actions/user';
import { destroyUserProfilesOptions } from '../../actions/userProfiles';
import { fetchUserProfileSingle } from '../../actions/userProfileSingle';
import privacyTerms from '../../assets/documents/privacyTerms.pdf';
import termsAndConditions from '../../assets/documents/termsAndConditions.pdf';
import history from '../../history';
import { getCurrentUserToken, userHasRole } from '../../lib/auth';
import { Endpoints as url } from '../../lib/endpoints';
import {
  toastError,
  validateEmail,
  validatePassword,
} from '../../lib/utilities';
import { PAGES_PATH } from '../../lib/variables';

const mapStateToProps = state => ({
  user: state.user,
  userProfile: state.userProfileSingle,
});

const mapDispatchToProps = dispatch => ({
  postUser: (username, user) => {
    dispatch(postUser(username, user));
  },
  destroyUserProfilesOptions: () => {
    dispatch(destroyUserProfilesOptions());
  },
  fetchUserProfileSingle: pid => {
    dispatch(fetchUserProfileSingle(pid));
  },
  destroyUser: () => {
    dispatch(destroyUser());
  },
});

class UserAddForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    const initialState = {
      user: {
        username: '',
        password: '',
        status: false,
        expiry: moment().add(1, 'year').format('YYYY-MM-DD HH:mm:ss.SSS'),
        emailNotify: false,
        termsAndContidions: true,
        privacyTerms: true,
        commercialAds: false,
        UserProfileId: null,
      },
      errors: {
        email: false,
        password: false,
        username: false,
      },
      userProfileSidebar: false,
      userProfilesOptions: [],
    };
    this.state = { ...initialState };

    this.handleUserProfileSidebar = this.handleUserProfileSidebar.bind(this);
    this.handleNewUserProfile = this.handleNewUserProfile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  componentDidMount() {
    userHasRole('admin') && this.axiosFetchUserProfilesOptions();
  }

  componentWillUnmount() {
    this.props.destroyUserProfilesOptions();
    this.props.destroyUser();
  }

  // HANDLER SECTION
  handleUserProfileSidebar(e) {
    e.preventDefault();
    this.setState({ userProfileSidebar: !this.state.userProfileSidebar });
  }

  handleChange(e, { name, value }) {
    if (name === 'UserProfileId') {
      this.props.fetchUserProfileSingle(value);
    }

    this.setState({ user: { ...this.state.user, [name]: value } });
  }

  handleCheck(e, { name, checked }) {
    this.setState({ user: { ...this.state.user, [name]: checked } });
  }

  handleNewUserProfile(name, value) {
    this.axiosFetchUserProfilesOptions(name, value);
  }

  handleSubmit(e) {
    // const { postUser } = this.props
    e.preventDefault();

    const { user } = this.state;
    const userCreated = { ...user };

    delete userCreated.passwordCheck;
    delete userCreated.status;
    delete userCreated.termsAndConditions;
    delete userCreated.privacyTerms;

    Axios.post(url.UserCreate(), JSON.stringify(userCreated), {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
    })
      .then(response => {
        const { id } = response.data;
        history.push({
          pathname: `${PAGES_PATH}/user/${id}`,
        });
      })
      .catch(err => toastError(err.response.data));
  }

  handleBlur(e) {
    const fieldToValidate = e.target.name;
    switch (fieldToValidate) {
      case 'email':
        if (validateEmail(e.target.value)) {
          this.setState({
            errors: { ...this.state.errors, [fieldToValidate]: false },
          });
        } else {
          this.setState({
            errors: { ...this.state.errors, [fieldToValidate]: true },
          });
        }
        break;
      case 'password':
        if (validatePassword(e.target.value)) {
          this.setState({
            errors: { ...this.state.errors, [fieldToValidate]: false },
          });
        } else {
          this.setState({
            errors: { ...this.state.errors, [fieldToValidate]: true },
          });
        }
        break;
      default:
        throw new Error('Cannot validate this field.');
    }
  }

  axiosFetchUserProfilesOptions(name = '', value = '') {
    Axios.get(url.UserProfilesOptions(), {
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCurrentUserToken(),
      },
    })
      .then(res => {
        if (name !== '' && value !== '') {
          const optionIdArray = res.data.filter(o => o.text === value);
          if (optionIdArray.length) {
            this.setState({
              userProfilesOptions: { options: res.data },
              user: {
                ...this.state.user,
                UserProfileId: optionIdArray[0].value,
              },
            });
          }
        } else {
          this.setState({
            userProfilesOptions: { options: res.data },
          });
        }
      })
      .catch(err => toastError(err.response.data));
  }

  render() {
    const {
      userProfileSidebar,
      errors,
      userProfilesOptions,
      user,
    } = this.state;

    return (
      <Fragment>
        <Header as="h1" style={{ marginTop: '.1em' }} floated="left">
          Create User
        </Header>
        <Button
          as={Link}
          key="list"
          compact
          icon
          color="red"
          labelPosition="left"
          floated="right"
          style={{ marginTop: '2.4em' }}
          to={`${PAGES_PATH}/users`}
        >
          <FontAwesomeIcon
            icon="list"
            className="icon"
            style={{ padding: '0.4em' }}
          />
          List
        </Button>
        <Divider clearing />
        <Sidebar
          as={Segment}
          inverted
          color="red"
          direction="right"
          animation="overlay"
          visible={userProfileSidebar}
          width="very wide"
        >
          <Button
            floated="right"
            onClick={this.handleUserProfileSidebar}
            size="mini"
          >
            close
          </Button>
          <Header floated="left" as="h2">
            New User Profile
          </Header>
          <Divider clearing inverted />
          <UserProfileCreate
            parentHandleNewUserProfile={this.handleNewUserProfile}
            handleUserProfileSidebar={this.handleUserProfileSidebar}
            inverted
          />
        </Sidebar>

        <Sidebar.Pusher>
          <Form onSubmit={this.handleSubmit} autoComplete="off">
            <Grid>
              <Grid.Column mobile={16} tablet={7} computer={7}>
                <Form.Input
                  width={8}
                  label="Username"
                  name="username"
                  icon="user"
                  required
                  onChange={this.handleChange}
                  autoComplete="new-username"
                />

                <Form.Input
                  width={8}
                  label="Password"
                  name="password"
                  icon="key"
                  type="password"
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  autoComplete="new-password"
                />
              </Grid.Column>

              <Grid.Column mobile={16} tablet={7} computer={7}>
                {errors.password && (
                  <Message negative>
                    <Message.Header>Password not valid.</Message.Header>
                    <p>Your password must be at least 8 characters long.</p>
                  </Message>
                )}
              </Grid.Column>
            </Grid>

            <Header as="h4">Account Details</Header>
            <Divider clearing />

            <Form.Group>
              <Form.Dropdown
                name="UserProfileId"
                label="Profile"
                placeholder="choose one..."
                width={8}
                selection
                required
                value={idx(user, _ => _.UserProfileId)}
                options={idx(userProfilesOptions, _ => _.options) || []}
                onChange={this.handleChange}
              />

              <Form.Button
                content="Create Profile"
                label="&nbsp;"
                onClick={this.handleUserProfileSidebar}
              />
            </Form.Group>

            {idx(user, _ => _.UserProfileId) && (
              <Grid.Column>
                <>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Role</Table.HeaderCell>
                        <Table.HeaderCell>max devices</Table.HeaderCell>
                        <Table.HeaderCell>max gateways</Table.HeaderCell>
                        <Table.HeaderCell>max groups</Table.HeaderCell>
                        <Table.HeaderCell>max uplink</Table.HeaderCell>
                        <Table.HeaderCell>max downlink</Table.HeaderCell>
                        <Table.HeaderCell>max tags</Table.HeaderCell>
                        <Table.HeaderCell>life</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>
                          {idx(this.props.userProfile, _ => _.role)}
                        </Table.Cell>
                        <Table.Cell>
                          {idx(this.props.userProfile, _ => _.maxdevices) ||
                            '∞'}
                        </Table.Cell>
                        <Table.Cell>
                          {idx(this.props.userProfile, _ => _.maxgateways) ||
                            '∞'}
                        </Table.Cell>
                        <Table.Cell>
                          {idx(this.props.userProfile, _ => _.maxgroups) || '∞'}
                        </Table.Cell>
                        <Table.Cell>
                          {idx(this.props.userProfile, _ => _.maxuplink) || '∞'}
                        </Table.Cell>
                        <Table.Cell>
                          {idx(this.props.userProfile, _ => _.maxdownlink) ||
                            '∞'}
                        </Table.Cell>
                        <Table.Cell>
                          {idx(this.props.userProfile, _ => _.maxtags) || '-'}
                        </Table.Cell>
                        <Table.Cell>
                          {idx(this.props.userProfile, _ => _.life) || '∞'}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </>
              </Grid.Column>
            )}

            <Header as="h4">Personal Info</Header>
            <Divider clearing />

            <Grid>
              <Grid.Column mobile={16} tablet={7} computer={7}>
                <Form.Input
                  width={8}
                  label="Email"
                  name="email"
                  required
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                />

                <Form.Input
                  width={8}
                  label="Firstname"
                  name="firstname"
                  required
                  onChange={this.handleChange}
                />

                <Form.Input
                  width={8}
                  label="Lastname"
                  name="lastname"
                  required
                  onChange={this.handleChange}
                />

                <Form.Input
                  width={8}
                  label="Company"
                  name="company"
                  required
                  onChange={this.handleChange}
                />

                <Form.Checkbox
                  name="emailNotify"
                  label="Notify User that his account has been created"
                  onChange={this.handleCheck}
                />

                <Form.Checkbox
                  name="termsAndConditions"
                  label={
                    <label>
                      I agree to the{' '}
                      <a
                        href={termsAndConditions}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Terms and Conditions
                      </a>{' '}
                    </label>
                  }
                  required
                  checked
                  onChange={this.handleCheck}
                />

                <Form.Checkbox
                  name="privacyTerms"
                  label={
                    <label>
                      I agree to the{' '}
                      <a href={privacyTerms} target="_blank" rel="noreferrer">
                        Privacy Terms
                      </a>{' '}
                    </label>
                  }
                  required
                  checked
                  onChange={this.handleCheck}
                />

                <Form.Checkbox
                  name="commercialAds"
                  label="I want to recieve commercial ads"
                  onChange={this.handleCheck}
                />
                <Form.Button
                  content="Create"
                  type="submit"
                  disabled={!!(errors.password || errors.email)}
                />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={7} computer={7}>
                {errors.email && (
                  <Message negative>
                    <Message.Header>Email not valid.</Message.Header>
                    <p>Please check inserted email.</p>
                  </Message>
                )}
              </Grid.Column>
            </Grid>
          </Form>
        </Sidebar.Pusher>
      </Fragment>
    );
  }
}

UserAddForm.propTypes = {
  userProfilesOptions: PropTypes.object,
  userProfile: PropTypes.object,
  destroyUserProfilesOptions: PropTypes.func,
  destroyUser: PropTypes.func,
  postUser: PropTypes.func,
  fetchUserProfileSingle: PropTypes.func,
};

const UserCreate = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserAddForm),
);

export { UserCreate };

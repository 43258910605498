/** @format */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Header, Modal, Input, Label } from 'semantic-ui-react';
import SVG from 'react-inlinesvg';

const UploadModal = ({ entity, label, open = false, onChange }) => {
  const [check, setCheck] = useState(null);

  const handleChange = (e, { value }) => {
    setCheck(value);
  };

  return (
    <Modal open={open} onClose={onChange} basic size="small">
      <div style={{ width: '40%' }}>
        <SVG src="/assets/images/robotRYS.svg" />
      </div>
      <Header as="h1" content="Are you sure??" />
      <Modal.Content>
        <h3>
          This action will {label === 'create' ? 'create/update' : label} all{' '}
          {entity}s contained in the file:
        </h3>
        <h4>WARNING, THIS ACTION IS NOT REVERSIBLE!</h4>
        <p>To proceed, please fill the field with the action type</p>
        <Label size="big" color="orange" style={{ marginBottom: '2em' }}>
          {label}
        </Label>
        <div>
          <Input
            name="check"
            placeholder="label..."
            width={8}
            onChange={handleChange}
          />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onChange} color="red" disabled={label !== check}>
          Proceed
        </Button>
        <Button onClick={onChange}>Cancel</Button>
      </Modal.Actions>
    </Modal>
  );
};

UploadModal.propTypes = {
  entity: PropTypes.string,
  label: PropTypes.string,
  open: PropTypes.bool,
  onChange: PropTypes.func,
};

export { UploadModal };

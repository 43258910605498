/** @format */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Divider, Icon, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { useInterval } from '../Hooks';
import { EntityImportListTable } from './EntityImportListTable';
import { Search } from './EntityImportListSearch';
import { Upload } from '../Upload';
import DefaultButton from '../../../components/Utils/DefaultButton';
import { fetchEntityManagerImportList } from '../../../actions/deviceManager';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import { ImportDevicesTab } from '../../ImportDevicesTab/ImportDevicesTab';

import { API_URL } from '../../../lib/variables';

/* import old import */
import { fetchApplications } from '../../../actions/applications';
import { fetchUsers } from '../../../actions/users';
import { userHasRole, isLoggedIn } from '../../../lib/auth';
/* end */

const WrapperImportDevicesTab = () => {
  const me = useSelector(state => state.me);
  const users = useSelector(state => state.users);
  const applications = useSelector(state => state.applications);

  const dispatch = useDispatch();

  const generateQSForApplications = (sq, uid) => {
    const params = {};
    if (userHasRole('admin'))
      params.qs = sq ? `label=${sq}&uid=${uid}` : `uid=${uid}`;
    else params.qs = sq ? `label=${sq}` : '';
    return params;
  };

  const handleonReloadImportDevices = () => {
    if (isLoggedIn()) {
      if (userHasRole('admin')) {
        dispatch(fetchUsers({ qs: 'not_roles[]=system&not_roles[]=admin' }));
      }
      dispatch(fetchApplications());
    }
  };

  return (
    <ImportDevicesTab
      userId={'id' in me ? me.id : null}
      users={users}
      applications={applications}
      onSeachApplications={(searchQuery, userId) => {
        dispatch(
          fetchApplications(generateQSForApplications(searchQuery, userId)),
        );
      }}
      onSeachUsers={searchQuery => {
        if (userHasRole('admin')) {
          dispatch(
            fetchUsers({
              qs: searchQuery
                ? `username=${searchQuery}&not_roles[]=system&not_roles[]=admin`
                : `not_roles[]=system&not_roles[]=admin`,
            }),
          );
        }
      }}
      onReload={handleonReloadImportDevices}
    />
  );
};

const EntitiesImport = ({ entity }) => {
  const [items, setItems] = useState([]);
  const [add, setAdd] = useState(false);
  const [delay, setDelay] = useState(1000);
  const [oldVersion, setOldVersion] = useState(false);

  const {
    list = { rows: [] },
    list: { offset = 0, limit = 10, size = null },
  } = useSelector(state => state.deviceManager);

  const dispatch = useDispatch();

  useInterval(() => {
    dispatch(
      fetchEntityManagerImportList(entity, {
        offset,
        limit,
      }),
    );
  }, delay);

  useEffect(() => {
    dispatch(
      fetchEntityManagerImportList(entity, {
        offset: list.offset,
        limit: list.limit,
      }),
    );

    return () => {
      return () => setDelay(null);
    };
  }, []);

  useEffect(() => {
    if (list && 'rows' in list) setItems(list.rows);
  }, [list]);

  useEffect(() => {
    if (add) setDelay(null);
    else setDelay(1000);
  }, [add]);

  const addNewImport = () => {
    setAdd(true);
  };

  const closeAddNewImport = () => {
    setAdd(false);
  };

  const onUploadFinished = () => {
    setAdd(false);
  };

  const onPageChange = (e, { activePage }) => {
    dispatch(
      fetchEntityManagerImportList(entity, {
        offset: (activePage - 1) * limit,
        limit,
      }),
    );
  };

  return (
    <>
      {!oldVersion && (
        <>
          {!add && (
            <>
              <DefaultButton
                path={`#`}
                icon={'plus-circle'}
                desc={'Upload new file'}
                onClick={addNewImport}
              />

              <Search />

              <EntityImportListTable
                entity={entity}
                items={items}
                pagination={{
                  offset,
                  limit,
                  size,
                }}
                onPageChange={onPageChange}
              />
            </>
          )}

          {add && (
            <>
              <DefaultButton
                path={`#`}
                icon={'times'}
                desc={'Close'}
                onClick={closeAddNewImport}
              />

              <Upload entity={entity} onUploadFinished={onUploadFinished} />

              <Message icon>
                <Icon name="help" />
                <Message.Content>
                  <Message.Header>CSV file structure</Message.Header>
                  The CSV file should adhere to a predefined format: <br />
                  <ul>
                    <li>
                      Example for type{' '}
                      <span
                        style={{
                          fontWeight: 'bolder',
                          textDecoration: 'underline',
                        }}
                      >
                        Create
                      </span>{' '}
                      to import/update {entity}s in bulk{' '}
                      <a
                        href={`${API_URL}/manager/static/import-${entity}s-example.csv`}
                      >
                        <Icon name="linkify" corner="top right" />
                        download
                      </a>
                    </li>
                    <li>
                      Example for type{' '}
                      <span
                        style={{
                          fontWeight: 'bolder',
                          textDecoration: 'underline',
                        }}
                      >
                        Delete
                      </span>{' '}
                      to delete {entity}s in bulk{' '}
                      <a
                        href={`${API_URL}/manager/static/delete-${entity}s-example.csv`}
                      >
                        <Icon name="linkify" corner="top right" />
                        download
                      </a>
                    </li>
                  </ul>
                </Message.Content>
              </Message>
            </>
          )}
        </>
      )}
      {oldVersion && entity === 'device' && (
        <>
          <WrapperImportDevicesTab />
        </>
      )}

      <Divider hidden />

      {entity === 'device' && (
        <Container textAlign="center">
          <Link
            to="#"
            onClick={() => {
              setOldVersion(!oldVersion);
            }}
            style={{ color: oldVersion ? 'rgb(0,0,0)' : 'rgb(216,216,216)' }}
          >
            use{' '}
            <span
              style={
                oldVersion ? { color: 'red', textTransform: 'uppercase' } : {}
              }
            >
              {oldVersion ? 'new' : 'old'}
            </span>{' '}
            version
          </Link>
        </Container>
      )}
    </>
  );
};

EntitiesImport.propTypes = {
  entity: PropTypes.string,
};

export { EntitiesImport };

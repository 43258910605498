/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Container,
  Icon,
  Pagination,
  Table,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
} from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { ProgressPercent } from '../Utils/Utils';
import { userHasRole } from '../../../lib/auth';

import { PAGES_PATH } from '../../../lib/variables';

import './EntityImportListTable.css';

const EntityImportListTable = ({
  entity,
  items = [],
  pagination = { page: 1, offset: 10, size: null },
  onPageChange = () => {},
}) => {
  const notify = description => {
    toast({
      title: 'COPIED TO CLIPBOARD',
      description,
      color: 'teal',
      type: 'success',
      icon: 'copy',
    });
  };

  return (
    <>
      <Table celled>
        <TableHeader>
          <TableRow>
            <TableHeaderCell width={3}>Type</TableHeaderCell>
            <TableHeaderCell width={4}>Status</TableHeaderCell>
            {userHasRole('admin') && <TableHeaderCell>User</TableHeaderCell>}
            <TableHeaderCell positive>Success</TableHeaderCell>
            <TableHeaderCell negative>Errors</TableHeaderCell>
            <TableHeaderCell>Total</TableHeaderCell>
            <TableHeaderCell width={3}>Duration</TableHeaderCell>
          </TableRow>
        </TableHeader>

        <TableBody>
          {items.map(item => {
            return (
              <TableRow key={item.id}>
                <TableCell>
                  <>
                    <Icon
                      name={
                        item.kind === 'create'
                          ? 'arrow alternate circle up'
                          : 'trash'
                      }
                      color={item.kind === 'create' ? 'green' : 'red'}
                    />
                    <Link to={`${PAGES_PATH}/import/${entity}/${item.id}`}>
                      {item.kind}
                      <Icon
                        name="linkify"
                        size="mini"
                        corner
                        style={{ verticalAlign: 'super' }}
                      />
                    </Link>
                    <br />
                    <span
                      className="list-item-id"
                      onClick={() => notify(item.id)}
                    >
                      <CopyToClipboard text={item.id}>
                        <span>{item.id}</span>
                      </CopyToClipboard>
                    </span>
                  </>
                </TableCell>
                <TableCell textAlign="center">
                  <ProgressPercent
                    status={item.status}
                    total={item.total}
                    success={item.success}
                    error={item.error}
                  />
                </TableCell>
                {userHasRole('admin') && (
                  <TableCell textAlign="left">{item.username}</TableCell>
                )}
                <TableCell positive style={{ color: 'green' }}>
                  {item.success}
                </TableCell>
                <TableCell negative style={{ color: 'red' }}>
                  {item.error}
                </TableCell>
                <TableCell>{item.total}</TableCell>
                <TableCell>
                  <span>
                    {moment
                      .utc(moment(item.updatedAt).diff(moment(item.createdAt)))
                      .format('HH:mm:ss.SSS')}
                  </span>
                  <br />
                  <span className="list-item-createdat">
                    created @{' '}
                    {moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                  </span>
                </TableCell>
              </TableRow>
            );
          })}
          {items.length <= 0 && (
            <TableRow>
              <TableCell colSpan={7} textAlign="center">
                <span style={{ color: 'gray', fontStyle: 'italic' }}>
                  No data
                </span>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      {items.length > 0 && (
        <Container textAlign="center">
          <Pagination
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            activePage={parseInt(pagination.offset / pagination.limit, 10) + 1}
            totalPages={Math.ceil((pagination.size || 0) / pagination.limit)}
            onPageChange={onPageChange}
          />
        </Container>
      )}
    </>
  );
};

EntityImportListTable.propTypes = {
  entity: PropTypes.string,
  items: PropTypes.array,
  pagination: PropTypes.object,
  onPageChange: PropTypes.func,
};

export { EntityImportListTable };

/** @format */

import React from 'react';
import { useParams } from 'react-router';

import { EntityImportSingle } from '../../containers/EntityManager/EntityImport/EntityImportSigle';

const DeviceImport = () => {
  // get params from URL
  const { entity, id: importId } = useParams();

  return (
    <>
      <EntityImportSingle entity={entity} importId={importId} />
    </>
  );
};

export { DeviceImport };
